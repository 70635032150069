/**
 * @module client/src/pages/LandingPage/LandingPage
 * @description This module contains the `LandingPage` component which represents the landing page of the application. 
 * It shows a multi-step progress bar, fetching data related to assets, beneficiaries, executors, and wills, and displays related information to the user. 
 * The page also includes sections like YouTube videos, FAQs, stats, and provides navigation for steps involving asset management, people management, distribution, and wills.
 */
import { Box, Container, Divider, Grid, Skeleton, Stack, Typography, useTheme, } from '@mui/material';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { ContextProvider } from 'App';
import { ContainedButton1, ContainedButton2 } from 'components/DesignSystem/Button';
import Icon from 'components/DesignSystem/Icon';
import Footer from 'components/featureComponent/landingPage/Footer';
import Header from 'components/featureComponent/landingPage/Header';
import YouTubeVideoSection from 'components/featureComponent/landingPage/YouTubeVideoSection';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { preChecksApi } from 'services/landingPageService';
import FAQSection from '../../components/featureComponent/landingPage/FAQSection';
import StatsCard from '../../components/featureComponent/landingPage/StatsCard';

/**
 * Custom connector for the Stepper component that provides a styled connector between steps.
 */
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 'calc(50% - 2px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
            border: '1px solid #FFA25C',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 1,
        border: '1px dashed #F47B20',
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

/**
 * Custom styled step icon root for each step in the stepper.
 */
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    border: ownerState.active || ownerState.completed
        ? '1px solid #FFA25C'
        : '1px solid #F47B20',
    zIndex: 1,
    width: 98,
    height: 97,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    // boxShadow: ownerState.active ? '0 4px 10px 0 rgba(0,0,0,.25)' : 'none',
}));

/**
 * A step icon component for each step in the stepper. Displays an icon depending on whether the step is active or completed.
 * 
 * @param {Object} props - The props for the component.
 * @param {boolean} props.active - Whether the step is active.
 * @param {boolean} props.completed - Whether the step is completed.
 * @param {node} props.icon - The icon to display.
 * @param {string} props.className - Additional className for custom styling.
 * 
 * @returns {JSX.Element} - The step icon component.
 */
function ColorlibStepIcon(props) {
    const { active, completed, icon, className } = props;
    const icons = {
        1: {
            active: <Icon name='GWD_icon_mypeople_progress_grey.svg' height={97} width={97} />,
            completed: <Icon name='GWD_icon_mypeople_progress_orange.svg' height={97} width={97} />,
        },
        2: {
            active: <Icon name='GWD_icon_assets_progress_grey.svg' height={98} width={97} />,
            completed: <Icon name='GWD_icon_assets_progress_orange.svg' height={98} width={97} />,
        },
        3: {
            active: <Icon name='GWD_icon_distribution_progress_grey.svg' height={98} width={97} />,
            completed: <Icon name='GWD_icon_distribution_progress_orange.svg' height={98} width={97} />,
        },
        4: {
            active: <Icon name='GWD_icon_lock_progress_grey.svg' height={97} width={97} />,
            completed: <Icon name='GWD_icon_lock_progress_orange.svg' height={97} width={97} />,
        },
    };

    // Choose icon based on active and completed status
    const currentIcon = completed ? icons[icon].completed : icons[icon].active;

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {currentIcon}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

/**
 * Array of step objects representing the main stages of the onboarding process.
 * Each step has a label and route associated with it.
 */
const steps = [
    { label: 'My People', route: '/myPeople' },
    { label: 'My Assets', route: '/assets' },
    { label: 'My Distribution', route: '/distribution' },
    { label: 'My Will', route: '/myWill' },
];
/**
 * LandingPage component that represents the main landing page of the application.
 * This page includes the progress bar, dynamic stepper, stats, and other sections like YouTube videos, FAQs, etc.
 */
const LandingPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(50);
    const [currentStep, setCurrentStep] = useState([false, false, false, false]);
    const { user } = useContext(ContextProvider);
    const [peopleCount, setPeopleCount] = useState(0);
    const [assetsCount, setAssetCount] = useState(0);
    const [distributedCount, setDistributedCount] = useState(0);
    const [resp, setResp] = useState({});
    const [loading, setLoading] = useState(true);

    /**
     * Fetches the user-related data for assets, beneficiaries, executors, and other related information.
     * It updates state variables to reflect the current data counts and handles loading state.
     */
    const fetchMyPeople = async () => {
        setLoading(true);  // Start loading
        try {
            const response = await preChecksApi();
            setResp(response.data);
            const { beneficiaries = [], executors = [], witnesses = [], assets = [] } = response.data;
            // Calculate the total count
            const totalPeopleCount = beneficiaries.length + executors.length + witnesses.length;
            const totalAssetsCount = assets.length;
            // Set the people count
            setPeopleCount(totalPeopleCount);
            setAssetCount(totalAssetsCount);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);  // End loading
        }
    };

    useEffect(() => {
        fetchMyPeople();
    }, [])
    /**
         * Determines whether the user is subscribed to a particular service based on their subscription status.
         */
    const subscribed = useMemo(() => {
        let consumed = true;
        user?.Subscriptions?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).forEach((sub) => {
            if (sub) {
                const userSub = sub.UserSubscription;
                if (userSub && userSub.SubscriptionId === 1) {
                    if (userSub.active === true) consumed = false;
                    else consumed = true;
                }
            }
        });
        return !consumed;
    }, [user]);

    /**
     * Performs pre-checks based on the fetched data to determine if the steps can be marked as completed or active.
     */
    const prechecks = useMemo(() => {
        const fullyDistributedAssets = [];
        const notFullyDistributedAssets = [];
        const distribution = [
            ...(resp?.assets ?? []),
            {
                id: null,
                information: { caption: 'Any other assets not included in the Assets that need to be distributed' },
                type: '',
                sub_type: 'Residual Assets',
            },
        ]
            .map(({ id, name, information }) => {
                let mapped = true;
                [{ id: 0, deaths: [] }, ...(resp?.scenarios ?? [])].forEach((sc) => {
                    const will = resp?.wills?.find((w) => w.PropertyId === id && sc.id === w.ScenarioId);
                    if (!will) {
                        mapped = false;
                    } else {
                        let value = 100;
                        let total = 0;
                        if (will?.distribution_type === 'value') {
                            value =
                                information?.value ??
                                information?.amount ??
                                information?.insured_amount ??
                                information?.investment_amount ??
                                information?.deposited_amount;
                        } else if (will?.distribution_type === 'quantity') {
                            value = information?.quantity ?? information?.qty ?? information?.number;
                        }
                        will?.mapping?.forEach((mapping) => {
                            total += +(mapping.share ?? 0);
                        });
                        if (Math.abs(Number((value - total).toFixed(2))) <= 0.00) {  // done 0.01 to 0.00
                            total = value;
                        }
                        mapped = mapped && total === value;
                    }
                });
                return {
                    name:
                        information?.name ??
                        information?.description ??
                        information?.description_or_name ??
                        information?.description_of_vehicle,
                    mapped,
                };
            })
            .forEach((asset) => {
                if (asset.mapped) {
                    fullyDistributedAssets.push(asset);
                } else {
                    notFullyDistributedAssets.push(asset);
                }
            });

        const assets = resp?.assets?.length > 0 ?? false;
        const beneficiaries = resp?.beneficiaries?.length > 0 ?? false;
        const executors = resp?.executors?.length > 0 ?? false;
        const witnesses = subscribed
            ? resp?.witnesses?.length === 2 ?? false
            : resp?.witnesses?.length === 2 || resp?.witnesses?.length === 1 || false;

        return { assets, fullyDistributedAssets, notFullyDistributedAssets, beneficiaries, executors, witnesses };
    }, [resp, user]);

    /**
     * Effect hook that runs whenever `prechecks` or `user` data changes.
     * It updates the current step status (`currentStep`) and the progress bar (`progress`) based on pre-check conditions.
     * 
     * The pre-checks determine the status of different sections like:
     * - Assets added (Step 3)
     * - People information completed (Step 2)
     * - Distribution completed (Step 4)
     * - Will status (Step 1)
     * 
     * The progress bar is updated based on the completion of these steps.
     */
    useEffect(() => {
        if (!prechecks) return;
        const { assets, fullyDistributedAssets, notFullyDistributedAssets, beneficiaries, executors, witnesses } = prechecks;
        setDistributedCount(fullyDistributedAssets.length);
        // Check each step condition and update the currentStep state accordingly
        setCurrentStep([
            beneficiaries && executors && witnesses, // Step 2: My People Added
            assets, // Step 3: Assets Added
            notFullyDistributedAssets.length === 0, // Step 4: Distribution Completed (only if there are no not fully distributed assets)
            !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt)
        ]);

        const Steps = [
            beneficiaries,
            executors,
            witnesses, // Step 2: My People Added
            assets, // Step 3: Assets Added
            notFullyDistributedAssets.length === 0, // Step 4: Distribution Completed (check based on not fully distributed)
            !!user?.Wills?.find(will => !will.uploaded && !will.deletedAt)
        ];

        // Calculate the progress
        const completedSteps = Steps.filter(step => step).length;
        const totalSteps = Steps.length;
        const newProgress = (completedSteps / totalSteps) * 100;
        setProgress(newProgress);
    }, [prechecks, user]);

    /**
     * Memoized function to sort steps based on their completion status.
     * It returns an array of steps with the completed steps appearing first.
     * 
     * @returns {Array} Sorted steps with completion status and their order.
     */
    const sortedSteps = useMemo(() => {
        return steps
            .map((step, index) => ({
                ...step,
                completed: currentStep[index],
                index,
            }))
            .sort((a, b) => (b.completed ? 1 : 0) - (a.completed ? 1 : 0));
    }, [currentStep]);

    /**
  * Memoized function to determine the active step based on the completion status.
  * 
  * @returns {number} Index of the active step.
  */
    const activeStep = useMemo(() => {
        return sortedSteps.findIndex(step => !step.completed);
    }, [sortedSteps]);

    /**
     * Handles the "Edit" button click, navigating the user to the "My People" page.
     */
    const handleEdit = () => {
        navigate('/myPeople');
        // setProgress(100);
    };
    /**
     * Handles step click, navigating the user to the route associated with the selected step.
     * 
     * @param {string} route - The route to navigate to.
     */
    const handleStepClick = (route) => {
        navigate(route);
    };

    return (
        <>
            <Helmet>
                <title> Dashboard | Get Will Done </title>
            </Helmet>
            <Container maxWidth='false' disableGutters sx={{ maxWidth: '120rem', padding: '0 31px', }}>
                <Header showExitButton />
                {/* First Box */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        bgcolor: theme.palette.grey[200],
                        borderRadius: '30px',
                        minHeight: 460,
                        width: '100%',
                        marginTop: '29px',
                        padding: '25px'
                    }}
                >
                    <Grid container height='100%'>
                        <Grid item container spacing={1} xs={12} md={8} sx={{ padding: 2 }}>
                            <Grid xs={12}>
                                <Stack>
                                    <Typography variant="largeTitle"
                                        color='#1E1C1B'>
                                        Welcome, {user.profile?.first_name}
                                    </Typography>
                                    <Typography variant="heading2" sx={{ mb: 2, color: '#707070' }}>
                                        You're just two steps away from creating your Will !
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={8} lg={8} xl={8}>
                                <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                                    {sortedSteps.map((step, index) => (
                                        <Step key={index} active={step.completed} completed={step.completed} onClick={() => handleStepClick(step.route)}>
                                            <StepLabel StepIconComponent={(props) => <ColorlibStepIcon {...props} icon={step.index + 1} />} />
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                            {/* <Grid item xs={12} md={8} mt={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FFFFFF', p: 2,mb: 1, borderRadius: '0.938rem' }}>
                                    {loading ? (
                                        <Skeleton variant="text" width={250} height={40} />
                                    ) : (
                                        <Typography variant="bodyText3">
                                            You have finished {Math.round(progress)}% of your Will
                                        </Typography>
                                    )}
                                    <ContainedButton1 sx={{ padding: '10px 33px' }} variant="contained" onClick={handleEdit} >
                                        <Typography variant="bodyText3"> Edit Your Will</Typography>
                                    </ContainedButton1>
                                </Box>
                            </Grid> */}
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FFFFFF', px: 2, py: { xs: 2, md: 0 }, mt: { xs: 2, md: 0 }, borderRadius: '0.938rem', minWidth: { xs: '100%', sm: '100%', md: '65%' } }}>
                                {loading ? (
                                    <Skeleton variant="text" width={250} height={40} />
                                ) : (
                                    <Typography variant="bodyText3" sx={{ mr: 1 }}>
                                        You have finished {Math.round(progress)}% of your Will
                                    </Typography>
                                )}
                                <ContainedButton1 sx={{ padding: '10px 33px' }} variant="contained" onClick={handleEdit} >
                                    <Typography variant="bodyText3"> Edit Your Will</Typography>
                                </ContainedButton1>
                            </Box>
                        </Grid>

                        <Grid item container spacing={1.4} xs={12} md={4}>
                            <Grid item xs={12}>
                                <StatsCard title="People Added" value={peopleCount} loading={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <StatsCard title="Assets Added" value={assetsCount} loading={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <StatsCard title="Assets Distributed" value={distributedCount} loading={loading} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                {/* Second Box */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'space-between',
                        bgcolor: theme.palette.secondary.lighter,
                        borderRadius: '30px',
                        minHeight: 460,
                        width: '100%',
                        marginTop: '20px',
                        padding: '25px'
                    }}
                >

                    <Grid item container xs={12} md={5.8} xl={6.8}>
                        <Grid item xs={12} md={12} xl={12}>
                            <Stack>
                                <Typography variant="largeTitle"
                                    color='#00838F'>
                                    Your Legacy,
                                </Typography>
                                <Typography variant="largeTitle" mt={-3}
                                    color='#00838F'>
                                    Our Commitment
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <ContainedButton2 sx={{ padding: '10px 16px', mt: '22px' }} variant="contained" onClick={() => navigate('/Services')} >
                                <Typography variant="bodyText3"> Explore Services</Typography>
                            </ContainedButton2>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={7.2} xl={6.2}>
                        <img
                            src={'assets/landing-page-family-img.png'}
                            alt="Description"
                            style={{
                                width: '795px',
                                height: '425px',
                                borderRadius: '15px',
                            }}
                        />
                    </Grid>

                </Box>

            </Container >
            {/* Third Box */}
            < Grid container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'space-between',
                    bgcolor: 'lightBlue',
                    height: 712,
                    width: '100%',
                    marginTop: '25px',
                    padding: '50px',
                    paddingRight: '0px',
                    backgroundImage: 'url(/assets/landing-page-box-bg-img.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }
                }
            >
                <Grid item xs={12} md={12} xl={12}>
                    <Typography variant="title1" color={theme.palette.secondary.main}> Resource Hub</Typography>
                </Grid>
                <Grid item container xs={12} md={12} xl={12} >
                    <Grid item xs={2} md={2} xl={2} mt={2}>
                        <Grid item>
                            <Typography variant="title2" color={theme.palette.grey[600]}>Videos</Typography>
                        </Grid>
                        <ContainedButton2 sx={{ padding: '8px 20px', mt: '25px' }} variant="contained" onClick={() => navigate('/resourceHub', { state: { reference: 'videos' } })} >
                            <Typography variant="bodyText3">Watch All →</Typography>
                        </ContainedButton2>
                    </Grid>
                    <Grid item xs={10} md={10} xl={10} sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>
                        <YouTubeVideoSection />
                    </Grid>
                </Grid>

                <Divider sx={{ width: '96%', height: '0px', border: '1px solid #C5C5C5' }} />
                <Grid item container xs={12} md={12} xl={12} >
                    <Grid item xs={2} md={2} xl={2} mt={2}>
                        <Grid item>
                            <Typography variant="title2" color={theme.palette.grey[600]}>FAQs</Typography>
                        </Grid>
                        <ContainedButton2 sx={{ padding: '8px 20px', mt: '25px' }} variant="contained" onClick={() => navigate('/resourceHub')} >
                            <Typography variant="bodyText3">Find More →</Typography>
                        </ContainedButton2>
                    </Grid>
                    <Grid item xs={10} md={10} xl={10} sx={{ overflowX: 'auto', '::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none' }}>
                        <FAQSection />
                    </Grid>
                </Grid>
            </Grid >
            <Footer />
        </>
    );
};

export default LandingPage;
