import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { ContextProvider } from 'App';
import { CustomAutocomplete, CustomAutocompleteFreeSolo } from 'components/DesignSystem/AutoComplete/CustomAutocomplete';
import Icon from 'components/DesignSystem/Icon';
import InputField from 'components/DesignSystem/InputField';
import RadioField from 'components/DesignSystem/RadioGroup/RadioField';
import useSwalAlert from 'components/DesignSystem/SwalAlert';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { fetchDataFromZip, updateProfileApi } from 'services/profileService';
import { CountryList } from 'utils/helper/CountryList';
import { handleCountryCodeChange } from 'utils/helper/FormHelperFunctions';
import formValidation from 'utils/helper/formValidations';
import * as Yup from 'yup';
import { ContainedButton1, TextedButton1 } from '../../../components/DesignSystem/Button';

let timeoutId;

const AddressDetails = () => {
    const [userProfile, setUserProfile] = useState(null);
    const [areaOptions, setAreaOptions] = useState([]);
    const [areaOptionsPermanent, setAreaOptionsPermanent] = useState([]);
    const [initialProfile, setInitialProfile] = useState(null); // Store the initial values
    const [isEdit, setIsEdit] = useState(false);
    const showSwalAlert = useSwalAlert();
    const { fetchUser } = useContext(ContextProvider);

    const theme = useTheme();

    const handleOnEditClick = () => {
        setIsEdit(edit => !edit);
    };

    const initialValues = {
        current: {
            house_no: '',
            house_name: '',
            area: '',
            country: '',
            countryCode: '',
            state: '',
            city: '',
            zip: ''
        },
        permanent: {
            house_no: '',
            house_name: '',
            area: '',
            country: '',
            countryCode: '',
            state: '',
            city: '',
            zip: ''
        },
        same_as_current: true
    };
    const validationSchema = Yup.object().shape({
        current: Yup.object().shape({
            house_no: formValidation.houseNoValidation,
            house_name: formValidation.houseNameValidation,
            area: formValidation.areaValidation,
            countryCode: formValidation.countryValidation,
            state: formValidation.stateValidation,
            city: formValidation.cityValidation,
            zip: formValidation.zipValidation,
        }),
        permanent: Yup.object().shape({
            house_no: formValidation.houseNoValidation,
            house_name: formValidation.houseNameValidation,
            area: formValidation.areaValidation,
            countryCode: formValidation.countryValidation,
            state: formValidation.stateValidation,
            city: formValidation.cityValidation,
            zip: formValidation.zipValidation,
        }),
        same_as_current: formValidation.same_as_currentValidation
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const profileData = { ...userProfile, ...values };
            try {
                await updateProfileApi({ profile: profileData })
                setIsEdit(false);
                await getUserData();
                await showSwalAlert({
                    title: "Address Details Saved Successfully",
                    icon: "success",
                    timer: 1500
                });
            } catch (error) {
                showSwalAlert({
                    title: "Failed to save Address Details",
                    icon: "error",
                    timer: 1500
                });
                console.error('Error updating profile:', error);
            };
        }
    });

    const getUserData = async () => {
        try {
            const { data } = await fetchUser();
            const profile = data.user?.profile;
            setUserProfile(profile);
            setInitialProfile(profile); // Store the initial values for later reset
            const currentAddress = data.user.profile?.current
            const permanentAddress = data.user.profile?.permanent
            formik.setValues({
                current: {
                    house_no: currentAddress?.house_no || '',
                    house_name: currentAddress?.house_name || '',
                    area: currentAddress?.area || '',
                    country: currentAddress?.country || '',
                    countryCode: currentAddress?.countryCode || '',
                    state: currentAddress?.state || '',
                    city: currentAddress?.city || '',
                    zip: currentAddress?.zip || ''
                },
                permanent: {
                    house_no: permanentAddress?.house_no || '',
                    house_name: permanentAddress?.house_name || '',
                    area: permanentAddress?.area || '',
                    countryCode: permanentAddress?.countryCode || '',
                    state: permanentAddress?.state || '',
                    city: permanentAddress?.city || '',
                    zip: permanentAddress?.zip || ''
                },
                same_as_current: data.user.profile.same_as_current
            });
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    const handleZipChange = (fieldPrefix, zip, countryCode, isPermanent = false) => {
        if (zip?.length >= 4 && zip?.length <= 11 && countryCode) {
            if (timeoutId) clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                fetchDataFromZip({ zip, countryCode })
                    .then(({ city, state, placeNames }) => {
                        formik.setFieldValue(`${fieldPrefix}.city`, city);
                        formik.setFieldValue(`${fieldPrefix}.state`, state);

                        // Set area options based on whether it's current or permanent
                        if (isPermanent) {
                            setAreaOptionsPermanent(placeNames);
                        } else {
                            setAreaOptions(placeNames);
                        }
                    })
                    .catch((error) => {
                        formik.setFieldValue(`${fieldPrefix}.city`, '');
                        formik.setFieldValue(`${fieldPrefix}.state`, '');

                        // Clear area options based on whether it's current or permanent
                        if (isPermanent) {
                            setAreaOptionsPermanent([]);
                        } else {
                            setAreaOptions([]);
                        }
                    });
            }, 1000);
        }
    };

    useEffect(() => {
        if (formik.values.same_as_current) {
            formik.setFieldValue('permanent', { ...formik.values.current });
        }
    }, [formik.values.current, formik.values.same_as_current]);

    const handleSameAsCurrentChange = (event) => {
        const isChecked = event.target.value === 'true';
        formik.setFieldValue('same_as_current', isChecked);

        if (isChecked) {
            formik.setFieldValue('permanent', { ...formik.values.current });
        } else {
            formik.setFieldValue('permanent', {
                house_no: '',
                house_name: '',
                area: '',
                country: '',
                countryCode: '',
                state: '',
                city: '',
                zip: ''
            });
        }
    };

    const menuItems = [
        {
            value: "india",
            label: "India"
        }
    ]

    const radioOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
    ];


    const handleCancel = () => {
        if (initialProfile) {
            formik.setValues({
                current: {
                    house_no: initialProfile?.current?.house_no || '',
                    house_name: initialProfile?.current?.house_name || '',
                    area: initialProfile?.current?.area || '',
                    country: initialProfile?.current?.country || '',
                    countryCode: initialProfile?.current?.countryCode || '',
                    state: initialProfile?.current?.state || '',
                    city: initialProfile?.current?.city || '',
                    zip: initialProfile?.current?.zip || ''
                },
                permanent: {
                    house_no: initialProfile?.permanent?.house_no || '',
                    house_name: initialProfile?.permanent?.house_name || '',
                    area: initialProfile?.permanent?.area || '',
                    countryCode: initialProfile?.permanent?.countryCode || '',
                    state: initialProfile?.permanent?.state || '',
                    city: initialProfile?.permanent?.city || '',
                    zip: initialProfile?.permanent?.zip || ''
                },
                same_as_current: initialProfile?.same_as_current
            });
        }
        setIsEdit(false); // Optionally, disable the edit mode
    };

    const handleSave = () => {
        formik.handleSubmit();
    }

    return (
        <>
            <Box
                sx={{
                    p: 2, // Padding inside the Box
                    backgroundColor: '#F5F5F5',
                    borderRadius: '15px',
                    marginTop: '96px',
                    marginRight: '94px'
                }}
            >
                <Box display='flex' alignItems='center' justifyContent='space-between' mt='8px'>
                    <Typography variant="heading2" color={theme.palette.secondary.main} gutterBottom>
                        Current Address
                    </Typography>
                    <Button
                        variant="outlined"
                        endIcon={<Icon name='gwd-icons-edit.svg' />}
                        sx={{
                            // width: '67px',
                            // height: '28px',
                            borderRadius: '15px',
                            padding: '3px 15px 5px 15px',
                            border: '0.7px solid #78BEC4',
                            backgroundColor: '#FFFFFF',
                            boxShadow: '6px 6px 8px -8px #00838F4D',
                            color: '#000000',
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: '#78BEC4',
                            },
                        }}
                        onClick={handleOnEditClick}
                    >
                        <Typography variant='suggestiveText' sx={{ color: '#00838F', pt: '4px' }}>Edit</Typography>
                    </Button>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                    <Box
                        container
                        sx={{
                            display: 'flex',
                            mt: '38px',
                            mr: '58px',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fullWidth
                                    name="current.house_no"
                                    placeholder="House/Flat No." z
                                    value={formik.values.current.house_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.house_no && Boolean(formik.errors.current?.house_no)}
                                    helperText={formik.touched.current?.house_no && formik.errors.current?.house_no}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField
                                    fullWidth
                                    name="current.house_name"
                                    placeholder="Building, Company, Apartment*" z
                                    value={formik.values.current.house_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.house_name && Boolean(formik.errors.current?.house_name)}
                                    helperText={formik.touched.current?.house_name && formik.errors.current?.house_name}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <CustomAutocompleteFreeSolo
                                    fullWidth
                                    name="current.area"
                                    placeholder="Area, Street, Sector*"
                                    value={formik.values.current.area}
                                    handleChange={formik.handleChange}
                                    onInputChange={formik.handleChange}
                                    options={areaOptions}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.area && Boolean(formik.errors.current?.area)}
                                    helperText={formik.touched.current?.area && formik.errors.current?.area}
                                    disabled={!isEdit}
                                    backgroundColor={theme.palette.common.white}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <CustomAutocomplete
                                    name="current.countryCode"
                                    value={formik.values.current.countryCode}
                                    options={CountryList}
                                    placeholder="Country*"
                                    handleChange={(event) => handleCountryCodeChange(formik, event, 'current.countryCode')}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.countryCode && Boolean(formik.errors.current?.countryCode)}
                                    helperText={formik.touched.current?.countryCode && formik.errors.current?.countryCode}
                                    disabled={!isEdit}
                                    backgroundColor={theme.palette.common.white}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.zip"
                                    placeholder="Pincode*" z
                                    value={formik.values.current.zip}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleZipChange('current', e.target.value, formik.values.current.countryCode);
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.zip && Boolean(formik.errors.current?.zip)}
                                    helperText={formik.touched.current?.zip && formik.errors.current?.zip} sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.state"
                                    placeholder="State*" z
                                    value={formik.values.current.state}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.state && Boolean(formik.errors.current?.state)}
                                    helperText={formik.touched.current?.state && formik.errors.current?.state}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <InputField
                                    fullWidth
                                    name="current.city"
                                    placeholder="City*" z
                                    value={formik.values.current.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.current?.city && Boolean(formik.errors.current?.city)}
                                    helperText={formik.touched.current?.city && formik.errors.current?.city}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                            height: '2.5rem'
                                        },
                                    }}
                                    disabled={!isEdit}
                                />
                            </Grid>
                        </Grid>

                        <Grid container display='flex' justifyContent='left' md={12} mt={2}>
                            <RadioField
                                label="This is my permanent address :"
                                name="same_as_current"
                                value={formik.values.same_as_current}
                                onChange={handleSameAsCurrentChange}
                                options={radioOptions}
                                onBlur={formik.handleBlur}
                                error={formik.touched.same_as_current && Boolean(formik.errors.same_as_current)}
                                helperText={formik.touched.same_as_current && formik.errors.same_as_current}
                                disabled={!isEdit}
                            />
                        </Grid>

                        {/* Permanent Address Section */}
                        {!formik.values.same_as_current && (
                            <>
                                <Divider sx={{ borderStyle: 'dashed', width: '100%', height: '1px', border: '0.0625rem dashed #C5C5C5', mt: '25px' }} />
                                <Grid container display='flex' justifyContent='left' mt='20px'>
                                    <Typography variant="heading2" sx={{ color: theme.palette.secondary.main }} >
                                        Permanent Address
                                    </Typography>
                                </Grid>
                                <Grid container spacing={2} mt={0.5} >
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            fullWidth
                                            name="permanent.house_no"
                                            placeholder="House/Flat No."
                                            value={formik.values.permanent.house_no}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.house_no && Boolean(formik.errors.permanent?.house_no)}
                                            helperText={formik.touched.permanent?.house_no && formik.errors.permanent?.house_no}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                            disabled={!isEdit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <InputField
                                            fullWidth
                                            name="permanent.house_name"
                                            placeholder="Building, Company, Apartment*"
                                            value={formik.values.permanent.house_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.house_name && Boolean(formik.errors.permanent?.house_name)}
                                            helperText={formik.touched.permanent?.house_name && formik.errors.permanent?.house_name}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                            disabled={!isEdit}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <CustomAutocompleteFreeSolo
                                            fullWidth
                                            name="permanent.area"
                                            placeholder="Area, Street, Sector*"
                                            value={formik.values.permanent.area}
                                            handleChange={formik.handleChange}
                                            onInputChange={formik.handleChange}
                                            options={areaOptionsPermanent}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.area && Boolean(formik.errors.permanent?.area)}
                                            helperText={formik.touched.permanent?.area && formik.errors.permanent?.area}
                                            disabled={!isEdit}
                                            backgroundColor={theme.palette.common.white}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <CustomAutocomplete
                                            name="permanent.countryCode"
                                            value={formik.values.permanent.countryCode}
                                            options={CountryList}
                                            placeholder="Country*"
                                            handleChange={(event) => handleCountryCodeChange(formik, event, 'permanent.countryCode')}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.countryCode && Boolean(formik.errors.permanent?.countryCode)}
                                            helperText={formik.touched.permanent?.countryCode && formik.errors.permanent?.countryCode}
                                            disabled={!isEdit}
                                            backgroundColor={theme.palette.common.white}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.zip"
                                            placeholder="Pincode"
                                            value={formik.values.permanent.zip}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                handleZipChange('permanent', e.target.value, formik.values.permanent.countryCode, true);
                                            }}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.zip && Boolean(formik.errors.permanent?.zip)}
                                            helperText={formik.touched.permanent?.zip && formik.errors.permanent?.zip}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                            disabled={!isEdit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.state"
                                            placeholder="State*"
                                            value={formik.values.permanent.state}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.state && Boolean(formik.errors.permanent?.state)}
                                            helperText={formik.touched.permanent?.state && formik.errors.permanent?.state}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                            disabled={!isEdit}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <InputField
                                            fullWidth
                                            name="permanent.city"
                                            placeholder="City*"
                                            value={formik.values.permanent.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.permanent?.city && Boolean(formik.errors.permanent?.city)}
                                            helperText={formik.touched.permanent?.city && formik.errors.permanent?.city}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: theme => theme.palette.common.white, // Override the background color for this instance
                                                    height: '2.5rem'
                                                },
                                            }}
                                            disabled={!isEdit}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>
                </form>
            </Box>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-end', gap: '10px', mt: '16px', mr: '94px'
            }}>
                <TextedButton1
                    sx={{ height: '40px', width: '109px' }}
                    onClick={handleCancel}
                    disabled={!isEdit}
                >
                    <Typography variant='bodyText3'>
                        Cancel
                    </Typography>
                </TextedButton1>

                <ContainedButton1
                    sx={{ height: '40px', width: '109px' }}
                    onClick={handleSave}
                    disabled={!isEdit}
                >
                    <Typography variant='bodyText3'>
                        Save
                    </Typography>
                </ContainedButton1>
            </Box>
        </>
    );
};

export default AddressDetails;
