/**
 * @module  Components/FeatureComponent/ResourcesSection
 * @description A section displaying a list of videos filtered based on a search query.
 * It renders videos using `iframe` and shows a message when no videos match the query.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {string} props.searchQuery - The search term used to filter the videos by their titles.
 *
 * @returns {JSX.Element} The ResourcesSection component.
 */

// ResourcesSection.js
import { Grid, Paper, Typography, useTheme } from '@mui/material';

const ResourcesSection = ({ searchQuery }) => {
    const theme = useTheme();

  /**
   * @constant
   * @type {Object}
   * @description Object containing video data with keys representing categories and values including the URL and title.
   */ const videos = {
        app: {
            url: 'https://www.youtube.com/embed/9rq1VBpjP-Y?si=PysElJiDyKQwq_po',
            title: 'GetWillDone Introduction Video',
        },
        assets: {
            url: 'https://www.youtube.com/embed/bX-NIh3XW-A?rel=0',
            title: 'Who can be a beneficiary?',
        },
        beneficiaries: {
            url: 'https://www.youtube.com/embed/s4OMayI0H_4?rel=0',
            title: 'What is a Beneficiary?',
        },
        executors: {
            url: 'https://www.youtube.com/embed/_n9984AxCZ0?rel=0',
            title: 'Who is an Executor of a Will?',
        },
        witnesses: {
            url: 'https://www.youtube.com/embed/SUE-XG-NBtg?rel=0',
            title: 'Who can be a witness to your Will?',
        },
        distribution: {
            url: 'https://www.youtube.com/embed/T_mauuyt5A0?rel=0',
            title: 'What happens in absence of a Will?',
        },
        preview: {
            url: 'https://www.youtube.com/embed/XZlLsQsYDuA?rel=0',
            title: 'What is a Will?',
        },
    };

    /**
     * @constant
     * @type {Array}
     * @description Array of videos filtered based on the `searchQuery`. Filters are case-insensitive.
     */
    const filteredVideos = Object.values(videos).filter((video) =>
        video.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <Grid
                item
                xs={6}
                md={12}
                xl={12}
                sx={{
                    mr: '294px',
                    mt: '15px',
                    overflowX: 'auto',
                    '::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none',
                }}
            >
                <Grid
                    container
                    spacing={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 'max-content',
                        borderRadius: '15px',
                        overflowX: 'auto',
                    }}
                >
                    {filteredVideos.length > 0 ? (
                        // Render filtered videos
                        filteredVideos.map((video, index) => (
                            <Grid item key={index}>
                                <Paper
                                    sx={{
                                        p: '11px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: 'none',
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <iframe
                                        width="100%"
                                        height="239px"
                                        src={video.url}
                                        title={video.title}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        style={{ borderRadius: '10px' }}
                                    />
                                    <Typography
                                        mt="8px"
                                        variant="bodyText4"
                                        sx={{ textAlign: 'left', width: '100%' }}
                                        color={theme.palette.grey[600]}
                                    >
                                        {video.title}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))
                    ) : (
                        // Message displayed if no videos match the search query
                        <Typography variant="bodyText4" color={theme.palette.grey[600]}>
                            No videos found matching your search.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default ResourcesSection;
